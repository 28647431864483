import React, { Component } from "react";
import  { Link } from "react-router-dom";
class Footer extends Component {
  render() {
    return (
<footer>
  <div className="container-fluid">
    <div className="row">
      <div className="col-sm-2" id="footer-column-one">
        <img className="footerLogo" src="/images/footer-logo.svg" alt="B.W. Clark Incorporated" />
      </div>
      <div className="col-sm-5" id="footer-column-two">
        <ul className="footerList locations">
          <li className="mainLocation">
            <h5>Headquarters</h5>
            <address>104 Corporate Blvd<br />
              Suite 416<br />
              West Columbia, SC 29169</address>
            <ul>
              <li>t <Link className="off-white" href="tel:8037792690">(803)779-2690</Link></li>
              <li>f (803)779-0049</li>
            </ul>
            {/* <div class="mailing">
                      <h5 class="">Mailing Address</h5>
                      <address>PO Box 41<br>
        Columbia, SC 29202</address>
                  </div> */}
          </li>
          <li>
            <h5>SELTROL OFFICE</h5>
            <ul>
              <li>t <Link className="off-white" href="tel:8642325371">(864)232-5371</Link></li>
            </ul>
          </li>
          <li>
            <h5>Mailing Address</h5>
            <address>PO Box 41<br />
              Columbia, SC 29202</address>
          </li>
          {/* <li>
                  <h5>CHARLESTON OFFICE</h5>
                  <address>1731 N Main St Ste E<br>
                    Summerville, SC 29483</address>
                  <ul>
                      <li>t 843 771 1769</li>
                  </ul>
              </li> */}
        </ul>
      </div>
      <div className="col-sm-5 footer-last" id="footer-column-three">
        <ul className="footerList links">
          <li className="footerProducts">
            <h5>Products</h5>
            <ul className="manufacturers">
              <li><Link className="off-white" to="/products-sensors">Sensors, Logic Controls, Touch Panels</Link></li>
              <li><Link className="off-white" to="/products-safety">Machine Safety</Link></li>
              <li><Link className="off-white" to="/products-motor-drives">Electric Motor Drives and Controls</Link></li>
              <li><Link className="off-white" to="/products-motors">Electric Motors, Controls and Accessories</Link></li>
              <li><Link className="off-white" to="/products-bus-level">Bus Level and Networking</Link></li>
              <li><Link className="off-white" to="/products-mechanical-drives">Mechanical Drives and Components</Link></li>
            </ul>
          </li>
          <li className="footerManufacturers">
            <h5>Manufacturers</h5>
            <ul className="manufacturers">
              <li><Link className="off-white" to="/products-banner">Banner</Link></li>
              <li><Link className="off-white" to="/products-turck">Turck</Link></li>
              <li><Link className="off-white" to="/products-redlion">Red Lion</Link></li>
              <li><Link className="off-white" to="/products-wago">Wago</Link></li>
              <li><Link className="off-white" to="/products-danfoss">Danfoss</Link></li>
              <li><Link className="off-white" to="/products-dynapar">Dynapar</Link></li>
              <li><Link className="off-white" to="/products-weg">WEG</Link></li>
              <li><Link className="off-white" to="/products-additional">Additional</Link></li>
            </ul>
          </li>
          <li className="siteMap text-right">
            <h5>Links</h5>
            <ul>
              <li><Link className="off-white" to="/">Home</Link></li>
              <li><Link className="off-white" to="https://www.seltrolshop.com" target="_blank">Online Store</Link></li>
              <li><Link className="off-white" to="/products">Products</Link></li>
              <li><Link className="off-white" >News</Link></li>
              <li><Link className="off-white" to="/about">About</Link></li>
              <li><Link className="off-white" >Contact</Link></li>
              <li><Link className="off-white" to="/pdfs/bwclark-linecard-09-15-2020.pdf" target="_blank">Line Card</Link></li>
            </ul>
            <br />
            <div className="socialMedia">
              <h5 className="mt-6">Follow Us</h5>
              <ul>
                <li><Link className="off-white" to="https://www.facebook.com/BWClarkSC" target="_blank"><i className="fab ml-0 fa-facebook-f" /></Link></li>
                <li><Link className="off-white" to="https://twitter.com/BWClark_INC" target="_blank"><i className="fab fa-twitter" /></Link></li>
                <li><Link className="off-white" to="https://www.linkedin.com/company/b-w-clark-inc-/" target="_blank"><i className="fab fa-linkedin-in" /></Link></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div className="subFooter">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-6">
          <p className="text-left py-0 my-0 pull-left">
            © 2018 BW Clark Incorporated. All Rights Reserved.
          </p>
        </div>
        <div className="col-sm-6">
          <p className="text-right py-0 my-0 pull-right">
            Design by <Link href="https://trevetts.com" className="off-white" target="_blank">Trevett’s</Link>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
);
}
};
export default Footer;
