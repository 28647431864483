import React, { Component } from "react";
import ReactDom from "react-dom"
import  { Link } from "react-router-dom";
import MotorDrives from "./motor-drives"
import ProductsAdditional from "./products-additional"
import ProductsBanner from "./products-banner"
import ProductsBusLevel from "./products-bus-level"
import ProductsDanFoss from "./products-danfoss"
import ProductsDynapar from "./products-dynapar"
import ProductsMechanicalDrives from "./products-mechanical-drives"
import ProductsMotorDrives from "./products-motor-drives"
import ProductsMotors from "./products-motors"
import ProductsRedLion from "./products-redlion"
import ProductsSafety from "./products-safety"
import ProductsSensors from "./products-sensors"
import ProductsTurck from "./products-turck"
import ProductsWago from "./products-wago"
import ProductsWeg from "./products-weg"
class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  handleItemClick = (itemName, event) => {
    event.preventDefault();
  // Logic to determine which component to display based on the clicked item
  // For example:
  let componentToShow;
  switch (itemName) {
    case 'sensors':
      componentToShow = <ProductsSensors />;
      break;
    case 'safety':
      componentToShow = <ProductsSafety />;
      break;
    case 'electricmotor':
      componentToShow = <ProductsMotorDrives />;
      break;
    case 'motors':
      componentToShow = <ProductsMotors />;
      break;
    case 'bus':
      componentToShow = <ProductsBusLevel />;
      break;
    case 'mechanical':
      componentToShow = <ProductsMechanicalDrives />;
    break;
    case 'banner':
      componentToShow = <ProductsBanner />;
      break;
    case 'turck':
      componentToShow = <ProductsTurck />;
      break;
    case 'redlion':
      componentToShow = <ProductsRedLion />;
    break;
    case 'wago':
      componentToShow = <ProductsWago />;
      break;
    case 'danfoss':
      componentToShow = <ProductsDanFoss />;
      break;
    case 'dynapar':
      componentToShow = <ProductsDynapar />;
    break;
    case 'weg':
      componentToShow = <ProductsWeg />;
      break;
    case 'additional':
      componentToShow = <ProductsAdditional />;
    break;
    // Add cases for other menu items similarly
    default:

      componentToShow = <ProductsSensors />; // Default to showing Sensors component
  }

  // Update the state to display the selected component
  this.setState({ display: componentToShow });
}
  render() {
    return (
      <section className="product-line-section gray-bg">
        <div className="container-fluid">
          <div className="row productRow">
            <div id="accordion" className="hide-desktop">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h4 className="mb-0 blue">
                    <a className data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Products
                    </a>
                  </h4>
                </div>
                <div id="collapseOne" className="collapse gray-bg" aria-labelledby="headingOne" data-parent="#accordion">
                  <div className="card-body">
                    <ul className="list-unstyled pl-0">
                      <li><a className="nav-link" onClick={() => this.handleItemClick('sensors')} href="#/">Sensors, Logic Controls, <br />Touch Panels &amp; More3</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseOne" className="nav-link" ui-sref="site.products-safety" ui-sref-active="active">Machine Safety Products</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseOne" className="nav-link" ui-sref="site.products.motor-drives" ui-sref-active="active">Electric Motor Drives &amp; Controls</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseOne" className="nav-link" ui-sref="site.products.motors" ui-sref-active="active">Electric Motors, Controls &amp; Accessories</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseOne" className="nav-link" ui-sref="site.products.bus-level" ui-sref-active="active">Bus Level &amp; Networking Products</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseOne" className="nav-link" ui-sref="site.products.mechanical-drives" ui-sref-active="active">Mechanical Drives &amp; Components</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h4 className="mb-0 blue">
                    <a className=" collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Manufacturers
                    </a>
                  </h4>
                </div>
                <div id="collapseTwo" className="collapse gray-bg" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="card-body">
                    <ul className="list-unstyled pl-0">
                      <li><a data-toggle="collapse" data-target="#collapseTwo" className="nav-link" ui-sref="site.products.banner" ui-sref-active="active">Banner</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseTwo" className="nav-link" ui-sref="site.products.turck" ui-sref-active="active">Turck</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseTwo" className="nav-link" ui-sref="site.products.redlion" ui-sref-active="active">Red Lion</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseTwo" className="nav-link" ui-sref="site.products.wago" ui-sref-active="active">Wago</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseTwo" className="nav-link" ui-sref="site.products.danfoss" ui-sref-active="active">Danfoss</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseTwo" className="nav-link" ui-sref="site.products.dynapar" ui-sref-active="active">Dynapar</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseTwo" className="nav-link" ui-sref="site.products.weg" ui-sref-active="active">WEG</a></li>
                      <li className="divider" />
                      <li><a data-toggle="collapse" data-target="#collapseTwo" className="nav-link" ui-sref="site.products.additional" ui-sref-active="active">Additional Manufacturers</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="sideBar">
              <div className="options white-bg">
                <h5 className="blue">Products</h5>
                <hr />
                <ul>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('sensors', e)} href="#/">Sensors, Logic Controls, Touch Panels &amp; More</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('safety', e)} href="#/">Machine Safety Products</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('electricmotor', e)} href="#/">Electric Motor Drives &amp; Controls</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('motors', e)} href="#/">Electric Motors, Controls &amp; Accessories</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('bus', e)} href="#/">Bus Level &amp; Networking Products</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('mechanical', e)} href="#/">Mechanical Drives &amp; Components</a></li>
                </ul>
              </div>
              <div className="options white-bg">
                <h5 className="blue">Manufacturers</h5>
                <hr />
                <ul>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('banner', e)} href="#/">Banner</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('turck', e)} href="#/">Turck</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('redlion', e)} href="#/">Red Lion</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('wago', e)} href="#/">Wago</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('danfoss', e)} href="#/">Danfoss</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('dynapar', e)} href="#/">Dynapar</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('weg', e)} href="#/">WEG</a></li>
                  <li><a className="nav-link" onClick={(e) => this.handleItemClick('additional', e)} href="#/">Additional Manufacturers</a></li>
                </ul>
              </div>
            </div>
            <div className="product-main">
              <div className="product-details white-bg float">
                <div id="maincard" ui-view>
                  {this.state.display}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    );
  }
};

export default Products;
