import React, { Component } from "react";
class Map extends Component {
  render() {
    return (
      <div style={{width: '100%', position: 'relative'}} ng-controller="MapController">
        <div id="map" style={{height: '100%', width: '100%', position: 'absolute'}} />
        <div className="container">
          <div className="row" style={{paddingTop: '100px', paddingBottom: '100px'}}>
            <div className="col-sm-offset-2 col-sm-5" style={{backgroundColor: 'rgba(255,255,255,0.8)'}}>
              <div style={{padding: '3em'}}>
                <div>
                  <h2>Directions</h2>
                </div>
                <div className="form-group">
                  <label htmlFor="currentLocationCheckbox">
                    <input type="checkbox" id="currentLocationCheckbox" ng-model="currentLocationChecked" ng-init="currentLocationChecked=true" />
                    Use my current location&nbsp;|&nbsp;  <em>or</em> Enter address</label>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Enter address" style={{width: '100%', position: 'relative'}} ng-model="directionsStartingPoint" ng-change="currentLocationChecked=!directionsStartingPoint" />
                </div>
                <div className="form-group">
                  <button className="btn btn-primary btn-lg" id="directonsBtn" ng-click="goToDirections();">Get Directions</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default Map;
