import React, { Component } from "react";
class ProductsMotorDrives extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Products</h1>
          <a href="contact" className="text-right white"><span className="yellow">Get a Quote →</span></a>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Electric Motor <br />Drives &amp; Controls</h2>
              <p className>We also carry products from the companies listed below and will be glad to assist
                you with any needs that you have.</p>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Danfoss<span className="underline" /></h3>
                  <ul>
                    <li>AC voltage vector drives up to 1000 HP with BUS compatibility</li>
                    <li>Solid state starters</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.danfoss.com/en-us/products/dds/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Fuji Electric<span className="underline" /></h3>
                  <ul>
                    <li>AC constant and variable torque drives and Monitouch HMIs</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://americas.fujielectric.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Contrex/Fenner<span className="underline" /></h3>
                  <ul>
                    <li>Digital controls for precise speed regulation of variable speed drives</li>
                    <li>Single axis servo systems</li>
                    <li>Trim cut shuttle rotary</li>
                    <li>Transverse controls</li>
                    <li>Reflex controls</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.contrexinc.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Yaskawa<span className="underline" /></h3>
                  <ul>
                    <li>AC Inverter Drives up to 2250HP</li>
                    <li>AC to AC, Ultra-low harmonics, full regeneration drive</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=yaskawa" target="_blank">Shop Here</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsMotorDrives;
