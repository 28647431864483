import React, { Component } from "react";
import  { Link } from "react-router-dom";
class Home extends Component {
  render() {
    return (
      <div>
        <section className="banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-md-11 offset-md-1 mr-auto" id="bannerContent">
                <p className="subHead">superior quality</p>
                <h1>Industrial automation SOLUTIONS for s<span>outh </span>c<span>arolina</span> and BEYOND</h1>
                <p className>BW Clark is committed to excellence in the distribution, application,
                  and support of superior quality industrial automation products.</p>
                <div className="d-flex flex-column flex-md-row lead" id="bannerBtns">
                  <Link to="https://www.seltrolshop.com" target="_blank" className="btn btn-lg btn-bd-primary mb-3 mb-md-0 mr-md-3 yellow-bg blue" onclick="ga('send', 'event', 'Jumbotron actions', 'Online Store', 'Online Store');">Online Store</Link>
                  <Link to="products" className="btn btn-lg btn-outline-secondary white mb-3 mb-md-0 mr-md-3" onclick="ga('send', 'event', 'Jumbotron actions', 'Get started', 'Get started');">Product
                    Lines</Link>
                  <Link to="contact" className="btn btn-lg btn-outline-secondary white" onclick="ga('send', 'event', 'Jumbotron actions', 'Download', 'Download 4.0.0');">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="banner">
        <div class="container-fluid d-flex h-100">
            <div class="row justify-content-center align-self-center">
                <div class="col-lg-10 offset-lg-1 col-md-11 offset-md-1 mr-auto" id="bannerContent">
                    <p class="subHead">superior quality</p>
                    <h1>Industrial automation SOLUTIONS for s<span>outh </span>c<span>arolina</span> and BEYOND</h1>
                    <p class="">BW Clark is committed to excellence in the distribution, application,
                        and support of superior quality industrial automation producs.</p>
                    <div class="d-flex flex-column flex-md-row lead" id="bannerBtns">
                        <a ui-sref="site.products.sensors" class="btn btn-lg btn-bd-primary mb-3 mb-md-0 mr-md-3 yellow-bg blue"
                          onclick="ga('send', 'event', 'Jumbotron actions', 'Get started', 'Get started');">Product Lines</a>
                        <a ui-sref="site.contact" class="btn btn-lg btn-outline-secondary white" onclick="ga('send', 'event', 'Jumbotron actions', 'Download', 'Download 4.0.0');">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
          </section> */}
        <section className="yellow-bg pt-5 pb-5 ng-scope" id="alert-section">
          <div id="covid" className="scroll-adjust" />
          <div className="container">
            <div className="row pt-5 pb-5">
              <div className="col-sm-12">
                <h2 className="blue mb-4">B. W. Clark has a new online store!</h2>
                <p>Check out our new online store featuring current product offerings from Banner, Turck, Red Lion, Wago and more! <a href="https://www.seltrolshop.com" target="_blank">Shop here!</a></p>
              </div>
            </div>
          </div>
        </section>
        <section className="light-blue-bg pt-5 pb-5 ng-scope" id="alert-section">
          <div id="covid" className="scroll-adjust" />
          <div className="container">
            <div className="row pt-5 pb-5">
              <div className="col-sm-12">
                <h2 className="blue mb-4">B.W. Clark has a new home!</h2>
                <p>After 52 years in the same building we've moved!&nbsp; We’re offering the same great service just in a new location.&nbsp; Please feel free to drop by and see us!</p>
                <span><strong>We're now located at:</strong></span><br />
                <address>104 Corporate Blvd<br />
                  Suite 416<br />
                  West Columbia, SC 29169</address>
              </div>
            </div>
          </div>
        </section>
        <section className="why">
          <div className="container-fluid gray-bg">
            <div className="row white">
              <div className="col-xl-10 col-lg-11 ml-auto mr-auto">
                <header className="text-center row">
                  <div className="col-md-6 text-center">
                    <img src="/images/icon/SVG/fifty-gold.svg" alt="Fifty Years of Excellence" />
                  </div>
                  <div className="col-md-6">
                    <div className="content">
                      <h2 className="blue mb-3">50 years <br />OF EXCELLENCE</h2>
                      <p className="text-left">B.W. Clark, Inc. has provided superior industrial
                        automation solutions to the businesses of South
                        Carolina for over fifty years.</p>
                    </div>
                  </div>
                </header>
              </div>
            </div>
          </div>
          <article className id="whyArticle">
            <div className="container float">
              <div className="row">
                <div className="col-md-6 order-md-1 col-img" id="location-img" />
                <div className="col-md-6 order-md-2 col-copy white-bg">
                  <div className="content">
                    <h3>Centrally Located<span className="underline" /></h3>
                    <p>The B.W. Clark main office and distribution center
                      is located in downtown Columbia’s historic Vista.
                      This central location is supplemented by account
                      management offices across the state to ensure quality
                      service to every region of South Carolina.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 order-md-2 col-img" id="service-img" />
                <div className="col-md-6 order-md-1 col-copy white-bg">
                  <div className="content">
                    <h3>Exceptional Service<span className="underline" /></h3>
                    <p>Our courteous, knowledgeable staff is highly efficient
                      in the marketing, sale, and delivery of superior
                      quality products and services at fair market prices.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 order-md-1 col-img" id="experience-img" />
                <div className="col-md-6 order-md-2 col-copy white-bg">
                  <div className="content">
                    <h3>Knowledge &amp; Experience<span className="underline" /></h3>
                    <p>With over 50 years of experience in the industry, B.W.
                      Clark is poised to render unmatched service and
                      support. In addition to product sales and distribution,
                      we have an in-house engineering department with
                      the experience to answer any questions that may
                      arise.</p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
        <section className="productLines" id="home-products">
          <div className="container">
            <div className="row white">
              <div className="col-sm-8">
                <header className="mb-5">
                  <h2 className="blue text-left">Our Products</h2>
                  <p>We offer a wide range of product lines to support our customers
                    needs. We distribute automation controls, components,
                    PLC’s, operator terminals, photoelectric sensors, fiber
                    optic, proximity sensors, modular sensors, timers,
                    counters, limit-switch style sensors, AC drives and
                    safety light curtains.</p>
                </header>
              </div>
            </div>
          </div>
          <article className id="productArticle">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 product-float">
                  <Link to="products-sensors">
                    <div className="card float">
                      <img className="card-img-top light-blue-bg" src="/images/sensors.png" alt="Sensors, Logic Controls and More" />
                      <div className="card-body">
                        <h3 className="card-title">Sensors, Logic <br />Controls &amp; More</h3>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 product-float">
                  <Link to="products-safety">
                    <div className="card float">
                      <img className="card-img-top light-blue-bg" src="/images/machine-safety.png" alt="Machine Safety Products" />
                      <div className="card-body">
                        <h3 className="card-title">Machine Safety <br />Products</h3>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 product-float">
                  <Link to="products-bus-level">
                    <div className="card float">
                      <img className="card-img-top light-blue-bg" src="/images/bus-level-networking.png" alt="Bus Level and Networking" />
                      <div className="card-body">
                        <h3 className="card-title">Bus Level <br />&amp; Networking</h3>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 product-float">
                  <Link to="products-motors">
                    <div className="card float">
                      <img className="card-img-top light-blue-bg" src="/images/electric-motor.png" alt="Electric Motors, Controls Accessories" />
                      <div className="card-body">
                        <h3 className="card-title">Electric Motors, <br />Controls Accessories</h3>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 product-float">
                  <Link to="products-mechanical-drives">
                    <div className="card float">
                      <img className="card-img-top light-blue-bg" src="/images/mechanical-drives.png" alt="Mechanical Drives and Components" />
                      <div className="card-body">
                        <h3 className="card-title">Mechanical Drives <br />&amp; Components</h3>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 product-float">
                  <Link to="products-motor-drives">
                    <div className="card float">
                      <img className="card-img-top light-blue-bg" src="/images/drives.png" alt="Electric Motro Drives and Controls" />
                      <div className="card-body">
                        <h3 className="card-title">Electric Motor Drives <br />and Controls</h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </article>
          <div className="col text-center">
            <Link to="products-sensors" className="btn btn-lg btn-bd-primary productBtn yellow-bg blue mt-5" onclick="ga('send', 'event', 'Jumbotron actions', 'Get started', 'Get started');">View All Products</Link>
          </div>
          {/* <a ui-sref="site.products.sensors" class="btn btn-lg btn-bd-primary productBtn blue-bg white"
      onclick="ga('send', 'event', 'Jumbotron actions', 'Get started', 'Get started');">View All Products</a> */}
        </section>
        <section className="productLines">
          <div className="container">
            <div className="row white">
              <div className="col-sm-12">
                <header>
                  <h2 className="blue text-left">Manufacturers</h2>
                </header>
              </div>
            </div>
            <div className="row white">
              <div className="col-sm-12">
                <ul className="brandGroup">
                  <li className="brand"><a href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=banner" target="_blank"><img src="/images/brands/bw-banner.jpg" alt="Banner" /></a></li>
                  <li className="brand"><a href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=turck" target="_blank"><img src="/images/brands/bw-turck.jpg" alt="Turck" /></a></li>
                  <li className="brand"><a href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=wago" target="_blank"><img src="/images/brands/bw-wago.jpg" alt="Wago" /></a></li>
                  <li className="brand"><a href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=red+lion" target="_blank"><img src="/images/brands/bw-red-lion-logo-new.jpg" alt="Red Lion" /></a></li>
                  <li className="brand"><a href="https://www.seltrolshop.com/brands/puls?utf8=%E2%9C%93&search=" target="_blank"><img src="/images/brands/bw-puls.jpg" alt="Puls" /></a></li>
                  <li className="brand"><a href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=yaskawa" target="_blank"><img src="/images/brands/bw-yaskawa.jpg" alt="Icotek" /></a></li>
                  <li className="brand"><a href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=larco" target="_blank"><img src="/images/brands/bw-larco.jpg" alt="Larco" /></a></li>
                  <li className="brand"><a href="https://bwclark.com/products/danfoss" target="_blank"><img src="/images/brands/bw-danfoss.jpg" alt="Danfoss" /></a></li>
                  <li className="brand"><a href="http://www.dynapar.com/" target="_blank"><img src="/images/brands/bw-dynapar.jpg" alt="Dynapar" /></a></li>
                  <li className="brand"><a href="http://www.weg.net/us/Products-Services/Electric-Motors" target="_blank"><img src="/images/brands/bw-weg.jpg" alt="WEG" /></a></li>
                  <li className="brand"><a href="https://www.marshbellofram.com/automatic-timing-controls/" target="_blank"><img src="/images/brands/bw-atc.jpg" alt="ATC" /></a></li>
                  <li className="brand"><a href="http://www.contrexinc.com/" target="_blank"><img src="/images/brands/bw-contrex.jpg" alt="Contrex" /></a></li>
                  <li className="brand"><a href="http://www.eaton.com/us/en-us.html" target="_blank"><img src="/images/brands/bw-eaton.jpg" alt="Eaton" /></a></li>
                  <li className="brand"><a href="http://www.fortressinterlocks.com/" target="_blank"><img src="/images/brands/bw-fortress.jpg" alt="Fortress Interlocks" /></a></li>
                  <li className="brand"><a href="https://americas.fujielectric.com/" target="_blank"><img src="/images/brands/bw-fuji.jpg" alt="Fuji Electric" /></a></li>
                  <li className="brand"><a href="http://www.grovegear.com/" target="_blank"><img src="/images/brands/bw-grove.jpg" alt="Grove Gear" /></a></li>
                  <li className="brand"><a href="https://www.haewa.com/" target="_blank"><img src="/images/brands/bw-hawa.jpg" alt="Haewa" /></a></li>
                  <li className="brand"><a href="https://www.pentairprotect.com/hoffman" target="_blank"><img src="/images/brands/bw-hoffman.jpg" alt="Hoffman" /></a></li>
                  <li className="brand"><a href="http://meltric.com/" target="_blank"><img src="/images/brands/bw-meltric.jpg" alt="Meltric" /></a></li>
                  <li className="brand"><a href="http://ep-us.mersen.com/" target="_blank"><img src="/images/brands/bw-mersen.jpg" alt="Mersen" /></a></li>
                  <li className="brand"><a href="http://www.redlion.net/n-tron" target="_blank"><img src="/images/brands/bw-ntron.jpg" alt="N-Tron" /></a></li>
                  <li className="brand"><a href="http://www.patlite.com/" target="_blank"><img src="/images/brands/bw-patlite.jpg" alt="Patlite" /></a></li>
                  <li className="brand"><a href="http://www.profaceamerica.com/" target="_blank"><img src="/images/brands/bw-proface.jpg" alt="Proface" /></a></li>
                  <li className="brand"><a href="https://www.reesinc.com/index.html" target="_blank"><img src="/images/brands/bw-rees.jpg" alt="Rees" /></a></li>
                  <li className="brand"><a href="https://www.roxtec.com/" target="_blank"><img src="/images/brands/bw-roxtec.jpg" alt="Roxtec" /></a></li>
                  <li className="brand"><a href="http://www.nidec-shimpo.com/" target="_blank"><img src="/images/brands/bw-shimpo.jpg" alt="Shimpo" /></a></li>
                  <li className="brand"><a href="http://www.redlion.net/sixnet" target="_blank"><img src="/images/brands/bw-sixnet.jpg" alt="Sixnet by Red Lion" /></a></li>
                  <li className="brand"><a href="https://www.stober.com/" target="_blank"><img src="/images/brands/bw-stober.jpg" alt="Stober" /></a></li>
                  <li className="brand"><a href="http://www.iboco.com/" target="_blank"><img src="/images/brands/bw-iboco.jpg" alt="Iboco" /></a></li>
                  <li className="brand"><a href="https://hornerautomation.com/" target="_blank"><img src="/images/brands/bw-horner.jpg" alt="Horner" /></a></li>
                  <li className="brand"><a href="http://lovatousa.com/" target="_blank"><img src="/images/brands/bw-lovato.jpg" alt="Lovato" /></a></li>
                  <li className="brand"><a href="http://www.sabcable.com/" target="_blank"><img src="/images/brands/bw-sab.jpg" alt="Sab" /></a></li>
                  <li className="brand"><a href="https://www.icotek.com/us/" target="_blank"><img src="/images/brands/bw-icotek.jpg" alt="Icotek" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="cta blue-gradient">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h2>Request a Quote</h2>
                <p>Call us today at <strong className="yellow">1 800 922 4127</strong>{/* or shoot us a message by following
                  the link below.*/}</p>
              </div>
            </div>
{/*            <div className="row">
              <div className="d-flex flex-column flex-md-row lead ml-auto mr-auto mt-5">
                <Link to="contact" className="btn btn-lg btn-bd-primary mb-3 mb-md-0 yellow-bg blue" onclick="ga('send', 'event', 'Jumbotron actions', 'Get started', 'Get started');">Get in Touch</Link>
              </div>
            </div> */}
          </div>
        </section>
        {/*
        <section className="blog gray-bg" id="news">
          <div className="container">
            <div className="row justify-content-center align-self-center">
              <header>
                <h2 className="text-center blue mb-5">Featured<span style={{display: 'inline'}} className="hide-mobile"> News</span>
                </h2>
              </header>
            </div>
            <div className="row">
              <div className="col-xl-4" ng-repeat="blog in home.blogList | limitTo:3">
                <div className="card blog-card float">
                  <a className="go-to" ui-sref="site.blog-page({'prettyID': blog.prettyID})">
                    <div className="thumbnail" style={{backgroundImage: 'url("{{blog.primaryImageURL}}")'}} />
                  </a>
                  <div className="card-body">
                    <p className="small date">{'{'}{'{'}blog.releaseDate{'}'}{'}'}</p>
                    <h3 className="card-title">{'{'}{'{'}blog.title | limitTo:48{'}'}{'}'}<span className="underline" /></h3>
                    <p className="card-text">{'{'}{'{'}blog.content | removeHTMLTags | limitTo:50{'}'}{'}'}...</p>
                    <a className="read-more blue" ui-sref="site.blog-page({'prettyID': blog.prettyID})">Read More</a>
                  </div>
                </div>
              </div>

            </div>
            {/*
            <div className="row">
              <div className="col text-center">
                <a href="/docs/4.0/getting-started/introduction/" className="btn btn-lg btn-bd-primary blue-bg white ml-auto mr-auto mt-5" ng-click="$state.go('site.blog')">View More</a>
              </div>
            </div>
          </div>
        </section>
        */}
      </div>

    );
  }
};
export default Home;
