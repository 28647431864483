import React, { Component } from "react";
class ProductsMotors extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Products</h1>
          <a href="contact" className="text-right white"><span className="yellow">Get a Quote →</span></a>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Electric Motors, <br />Controls &amp; <br className="hide-desktop" />Accessories</h2>
              <p className>We also carry products from the companies listed below and will be glad to assist
                you with any needs that you have.</p>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Weg<span className="underline" /></h3>
                  <ul>
                    <li>Electric motors</li>
                    <li>Power transformers</li>
                    <li>Frequency drives and controls</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.weg.net/us/Products-Services/Electric-Motors" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Wago<span className="underline" /></h3>
                  <ul>
                    <li>CAGE CLAMP® terminal blocks</li>
                    <li>Connectors</li>
                    <li>Electronic modules</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=wago" target="_blank">Shop Here</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Haewa<span className="underline" /></h3>
                  <ul>
                    <li>Electrical and electronic enclosures</li>
                    <li>Modular cabinets</li>
                    <li>Consoles</li>
                    <li>Climate control products</li>
                    <li>Punching tools and accessories</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.haewa.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Lovato<span className="underline" /></h3>
                  <ul>
                    <li>Motor control and protection</li>
                    <li>Control and signaling</li>
                    <li>Isolation and protection</li>
                    <li>Automation and control</li>
                    <li>Energy management</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://lovatousa.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Icotek<span className="underline" /></h3>
                  <ul>
                    <li>Cable entry systems for terminated and standard cables</li>
                    <li>Strain relief systems</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.icotek.com/us/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>SAB North America<span className="underline" /></h3>
                  <ul>
                    <li>Flexible cable for control and automation</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.sabcable.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Mersen/Ferraz Shawmut<span className="underline" /></h3>
                  <ul>
                    <li>Fuses</li>
                    <li>Fuse blocks</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://ep-us.mersen.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Eaton (Cooper Logic/Moeller)<span className="underline" /></h3>
                  <ul>
                    <li>A comprehensive line of distribution, general purpose and automation
                      controls.
                    </li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.eaton.com/us/en-us.html" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Hoffman Engineering<span className="underline" /></h3>
                  <ul>
                    <li>Complete line of NEMA and JIC enclosures</li>
                    <li>CLimate control products</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.pentairprotect.com/hoffman" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Dynapar/Lakeshore/<br className="hide-desktop" />Northstar<span className="underline" /></h3>
                  <ul>
                    <li>Mill duty digital tachometers</li>
                    <li>Pulse generators and encoders</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.dynapar.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Meltric<span className="underline" /></h3>
                  <ul>
                    <li>Switch rated industrial plugs and receptacles</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://meltric.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Rees<span className="underline" /></h3>
                  <ul>
                    <li>Heavy duty pushbutton operators and controls</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.reesinc.com/index.html" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Roxtec<span className="underline" /></h3>
                  <ul>
                    <li>Cable entry systems</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.roxtec.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsMotors;
