import React, { Component } from "react";
class Stripe extends Component {
  render() {
    return (
      <section className="top-section" trev-cms-pad>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h3>Card Basic / Product</h3>
              <p>Amount $20.00, default button</p>
              <button className="btn btn-primary" ng-click="checkout.basic()">Pay Now</button>
            </div>
            <div className="col-sm-6">
              <form name="recurringForm" ng-init="checkout.form={}">
                <h3>Card Recurring / Donation</h3>
                <div className="form-group">
                  <label>Amount</label>
                  <div className="text-danger pull-right" ng-show="!recurringForm.amount.$valid"><i>Please provide dollars, or dollars and cents</i></div>
                  <div className="input-group">
                    <span className="input-group-addon">$</span>
                    <input name="amount" className="form-control" type="text" ng-model="checkout.form.amount" ng-pattern="/^[0-9]+(\.[0-9]{2})?$/" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Interval units:</label>
                  <div className="text-danger pull-right" ng-show="!recurringForm.units.$valid"><i>Please provide a whole number</i></div>
                  <input name="units" className="form-control" type="number" ng-model="checkout.form.units" ng-pattern="/^[1-9][0-9]*$/" ng-init="checkout.form.units=1" />
                </div>
                <div className="form-group">
                  <label>Interval type:</label>
                  <select className="form-control" type="text" ng-options="interval as interval for interval in ['days','weeks','months']" ng-model="checkout.form.interval" ng-init="checkout.form.interval='months'">
                  </select>
                </div>
                <div className="form-group">
                  <button className="btn btn-default" ng-click="checkout.recurring()">Setup Recurring</button>
                </div>
              </form>
            </div>
            <div className="col-sm-12">
              <p><br />Response:</p>
              <pre>{"{"}{"{"}checkout.form._response | json{"}"}{"}"}</pre>
            </div>
          </div>
        </div>
      </section>

    );
  }
};
export default Stripe;
