import React, { Component } from "react";
import  { Link } from "react-router-dom";
class About extends Component {
  render() {
    return (

      <section className="example-section-2" id="about-banner">
        <div className="overlay" ng-class="{'sharp':scroll == true}" />
        <h1 className="text-center white mb-0" ng-class="{'scrolled':scroll == true}"><p className="date small text-center mb-2"><span>About Us</span></p>The B.W. Clark Story</h1>
        <div className="container">
          <div className="row">
            <div className="col-sm-10 ml-auto mr-auto white containment mb-5">
              <div className="content" in-view="viewFunction($inview)">
                <header className="mb-5">
                  <h2 className="white mb-3">About Us</h2>
                  <p className="intro white">B.W. Clark, Inc. is located in the historic Congaree Vista area of
                    downtown Columbia, South Carolina. We feel that being located in
                    the center of the state allows us to quickly respond to any need
                    a customer might have.</p>
                  <p className="white">Our account managers are stationed all across the state to ensure no
                    region of South Carolina is left uncovered. With decades of experience
                    within our company, B.W. Clark is poised to render unmatched service
                    and support.
                  </p>
                </header>
                <h2 className="mb-3">Company History</h2>
                <h3 className="mt-4 mb-3">Origins</h3>
                <p>B.W. Clark’s origins date back to February of 1968, under the name
                  of J.W. Vaughan Co., Inc. of Columbia. The original three stockholders
                  of the company were W.M. Clark, H.B. Tomlin, Jr., and O.N. Eastland.
                  Mr. Tomlin and Mr. Eastland were equal partners in J.W. Vaughan
                  Co., of Greenville SC, an industrial distributor which had served
                  the Piedmont area of South Carolina since the 1920’s.</p>
                <p> The Columbia business was developed to meet the growing industrial
                  needs of the midlands and coastal areas of South Carolina. Industrial
                  development from the early 1960’s indicated an expanding statewide
                  market for the company’s lines.</p>
                <h3 className="mt-5 mb-3">Growth</h3>
                <p>Mr. Clark was President of the new company, and managed the business
                  with an initial staff of four full-time employees. The company
                  grew steadily over the next few years and in 1982 began promoting
                  new lines including electronic counters and controls and solid-state
                  switching devices. The introduction of new lines continued into
                  1983 as the company increased business in high tech lines.</p>
                <h3 className="mt-5 mb-3">Turbulence</h3>
                <p>In November of 1989, a fire caused extensive damage to the building
                  adjoining the corporate office. The stockholders purchased the
                  adjoining building in order to provide expansion of office and
                  warehouse space as needed. The addition of this building gave the
                  company a total of 17,120 square feet of office and warehouse space.</p>
                <p className="mb-5">The company continued to grow and expand as it added additional products
                  that served the automated industrial manufacturing market. In 2000
                  the company was purchased by Barry W. Clark who became its President.
                  The following year the name of the Corporation was changed to B.W.
                  Clark Inc.</p>
                <h2 className="mb-3">Get in Touch</h2>
                <p>If you're looking for a quote, or have a question about what services
                  or products we offer, please don't hesitate to get in touch. We
                  look forward to hearing from you!</p>
                <div className="row mb-5">
                  <div className="d-flex flex-column flex-md-row lead ml-auto mr-auto mt-5">
                    <Link to="/contact" className="btn btn-lg btn-bd-primary mb-3 mb-md-0 yellow-bg blue" >Get in Touch</Link>
                  </div>
                </div>
                {/* <h3>Locations<span class="underline"></span></h3>

                    <h4>Corporate Headquarters</h4>
                    <address>1209 Gadsden Street<br>
Columbia, SC 29201</address>
                      <ul class="list-unstyled">
                        <li>Phone: 803-779-2690</li>
                        <li>Fax: 803-779-0049</li>
                    </ul>

                    <h4>Charleston Office</h4>
                    <address>
1731 North Main Street Ste E<br>
Summerville, SC 29483</address>
                    <ul class="list-unstyled">
                        <li>
                            Phone: 843-771-1769</li>
                    </ul>

                    <h4>Greenville Office</h4>
                    <address>
520 South Main Street<br>
Simpsonville, SC 29681</address>
                    <ul class="list-unstyled">
                        <li>
                            Greenville Phone: 864-220-1700</li>
                        <li>
                            Greenville Fax: 864-220-5050</li>
                    </ul>



                    Correspond by mail … B.W. Clark, Inc. PO Box 41 Columbia, SC 29202 Or email us… sales@bwclark.com */}
              </div>
            </div>
          </div>
        </div></section>
    );
  }
};
export default About;
