import React, { Component } from "react";
class ProductsAdditional extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Manufacturers</h1>
          <a href="contact" className="text-right white"><span className="yellow">Get a Quote →</span></a>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Additonal Manufacturers</h2>
              <p className="w-50">We also carry products from the companies listed below and will be glad to assist
                you with any needs that you have. Please click the manufacturers name to be
                directed to the companies website.</p>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <a href="http://www.redlion.net/sixnet" target="_blank"><h3>Sixnet by Red Lion<span className="underline" /></h3></a>
                  <p>Industrial Connectivity and Industrial Wireless</p>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.redlion.net/sixnet" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <a href="http://www.patlite.com/" target="_blank"><h3>Patlite<span className="underline" /></h3></a>
                  <p>signal towers, rotating lights, signal horns and alarms</p>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.patlite.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <a href="http://www.pulspower.com/home/" target="_blank"><h3>Puls<span className="underline" /></h3></a>
                  <p> Din rail mounted DC power supplies, single phase and 3 phase, DC UPS</p>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.seltrolshop.com/brands/puls?utf8=%E2%9C%93&search=" target="_blank">Shop Here</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <a href="http://www.profaceamerica.com/cms/site/841df35d572b686e/index.html" target="_blank"><h3>Proface/Xycom<span className="underline" /></h3></a>
                  <p>graphic operator interface devices, HMI panels and Industrial PC’s</p>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.profaceamerica.com/cms/site/841df35d572b686e/index.html" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <a href="http://www.meltric.com/" target="_blank"><h3>Meltric<span className="underline" /></h3></a>
                  <p>switch rated industrial plugs and receptacles</p>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.meltric.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <a href="http://www.n-tron.com/" target="_blank"><h3>N-TRON<span className="underline" /></h3></a>
                  <p> highly reliable industrial network products for industrial Ethernet communications</p>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.n-tron.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <a href="http://www.eaton.com/Electrical/USA/index.htm" target="_blank"><h3>EATON<span className="underline" /></h3></a>
                  <p>(Copper Logic/ Moeller) – a comprehensive line of distribution, general
                    purpose and</p>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.eaton.com/Electrical/USA/index.htm" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <a href="http://ep-us.mersen.com/" target="_blank"><h3>Mersen/Ferraz Shawmut<span className="underline" /></h3></a>
                  <p>fuses, fuse blocks</p>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://ep-us.mersen.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <a href="http://www.iboco.com/" target="_blank"><h3>IBOCO<span className="underline" /></h3></a>
                  <p> wire management systems</p>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="http://www.iboco.com/" target="_blank">View Site</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default ProductsAdditional;
