import React, { Component } from "react";
class ProductsDynapar extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Manufacturers</h1>
          <a href="contact" className="text-right white"><span className="yellow">Get a Quote →</span></a>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Dynapar</h2>
              <p>Please visit <a href="http://www.dynapar.com/" target="_blank">Dynapar online</a> to view their
                full line of products</p>
              <a href="http://www.dynapar.com/" target="_blank"><img className="product-logo" src="/images/brands/bw-dynapar.jpg" alt="Dynapar" /></a>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>ACURO™ Absolute Rotary Encoders<span className="underline" /></h3>
                  <ul>
                    <li>A125™ DeviceNet Interface Absolute Encoder</li>
                    <li>A125™ Profibus Absolute Encoder</li>
                    <li>A125™ Interbus Absolute Encoder</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>General Purpose Incremental Encoders<span className="underline" /></h3>
                  <ul>
                    <li>E12™ Commercial-Light Duty Incremental Encoders</li>
                    <li>E14™ Commercial-Light Duty Incremental Encoders</li>
                    <li>EC23™ Commercial-Light Duty Compact Encoders</li>
                    <li>H20™ Industrial Duty Encoders</li>
                    <li>X25™ Industrial Duty Explosion-Proof Encoders</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3> Motor Mount Incremental Encoders<span className="underline" /></h3>
                  <ul>
                    <li>E9™ Commercial-Light Duty Miniature Incremental Encoders</li>
                    <li>HS20™ Industrial Duty Sealed Hollowshaft Encoders</li>
                    <li>M15™ Modular Encoders for Servo/Commutation Motors</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsDynapar;
