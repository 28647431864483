import React, { Component } from "react";
class ProductsTurck extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Manufacturers</h1>
          <a href="contact" className="text-right white"><span className="yellow">Get a Quote →</span></a>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Turck</h2>
              <p>Shop <a href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=turck" target="_blank">Turck</a> products here</p>
              <a href="http://www.turck-usa.com/" target="_blank"><img className="product-logo" src="/images/brands/bw-turck.jpg" alt="Turck" /></a>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Connectivity<span className="underline" /></h3>
                  <ul>
                    <li>Cordsets <span>(eurofast, microfast, minifast, multifast, pentafast, picofast,
                        powerfast and versafast)</span></li>
                    <li>Accessories <span>(Cable Glands, Cord Grips, Lock Nuts, Protective Sleeving,
                        Protective Tubing)</span></li>
                    <li>Field Wireables</li>
                    <li>Multibox Junction Boxes</li>
                    <li>Receptacles</li>
                    <li>Splitters and Tees</li>
                    <li>Networks / Fieldbus <span>(CANopen, DeviceNet, Ethernet/IP and Profibus)</span></li>
                    <li>Bulk Cable</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Interfaces<span className="underline" /></h3>
                  <ul>
                    <li>Couplers and Solenoid Drivers</li>
                    <li>Level Controllers</li>
                    <li>Logic and Set-Point Controllers</li>
                    <li>Rotational Speed Monitor</li>
                    <li>Switching Amps</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Intrinsic Safety<span className="underline" /></h3>
                  <ul>
                    <li>Analog Input Isolators</li>
                    <li>Analog Output Isolators</li>
                    <li>Discrete Input Isolators</li>
                    <li>Discrete Output Isolators</li>
                    <li>I/O Systems</li>
                    <li>Zener Diode Barriers</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Linear Displacement Transducers (LDT's)<span className="underline" /></h3>
                  <ul>
                    <li>Level Probe</li>
                    <li>Profile Style</li>
                    <li>Q-Track</li>
                    <li>Rod Style</li>
                    <li>"WI" Series</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Process Connectivity<span className="underline" /></h3>
                  <ul>
                    <li>2 Wire Analog or HART Control Circuits <span>(eurofast, minifast and multifast)</span></li>
                    <li>Analog or Discrete Control Circuits <span>(eurofast, minifast and multifast)</span></li>
                    <li>NAMUR Circuits <span>(eurofast and multifast)</span></li>
                    <li>Extremelife Marine Cable <span>(minifast)</span></li>
                    <li>Bulk Cable <span>(reelfast)</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Relays and Sockets<span className="underline" /></h3>
                  <ul>
                    <li>Interfacer</li>
                    <li>IRC</li>
                    <li>MR-C Plus</li>
                    <li>QR-C Plus</li>
                    <li>Time Cube</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>RFID<span className="underline" /></h3>
                  <ul>
                    <li>Tags - (Data Carriers)</li>
                    <li>Transceivers - (Read/Write Heads)</li>
                    <li>Gateways</li>
                    <li>Handhelds</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Sensors<span className="underline" /></h3>
                  <ul>
                    <li>Capacitive</li>
                    <li>Barrel Style</li>
                    <li>Flow</li>
                    <li>Inclinometer</li>
                    <li>Inductive</li>
                    <li>Analog, Harsh Duty, High Temperature, Limit Switch, Long Range</li>
                    <li>Intrinsically Safe</li>
                    <li>Level</li>
                    <li>Capacitive and Probe Style</li>
                    <li>Pressure</li>
                    <li>Temperature</li>
                    <li>Ultrasonic</li>
                    <li>Diffused Mode and Retro Reflective Mode</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsTurck;
