import React, { Component } from "react";
class ProductsRedLion extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Manufacturers</h1>
          <a href="contact" className="text-right white"><span className="yellow">Get a Quote →</span></a>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Red Lion</h2>
              <p>Shop <a href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=red+lion" target="_blank">Red Lion online</a> products here</p>
              <a href="http://www.redlion.net/" target="_blank"><img className="product-logo" src="/images/brands/bw-red-lion-logo-new.jpg" alt="Red Lion" /></a>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Human Machine Interface (HMI)<span className="underline" /></h3>
                  <ul>
                    <li>Operator Interface <span>(G3 Series Touchscreens)</span></li>
                    <li>ProducTVity Station</li>
                    <li>Data Station Plus</li>
                    <li>Large Displays</li>
                    <li>Large Graphic Displays</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Digital and Analog<span className="underline" /></h3>
                  <ul>
                    <li>Counters</li>
                    <li>Digital Panel Meters</li>
                    <li>Rate</li>
                    <li>Timers</li>
                    <li>LD Large Displays</li>
                    <li>Miniature Displays</li>
                    <li>CUB5 Analog Panel Meters</li>
                    <li>Power Monitor</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Process Control<span className="underline" /></h3>
                  <ul>
                    <li>Modular Controller</li>
                    <li>PID Controllers</li>
                    <li>Data Acquisition</li>
                    <li>Signal Conditioning</li>
                    <li>Communication Modules</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsRedLion;
