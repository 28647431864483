import React, { Component } from "react";
class MotorDrives extends Component {
  render() {
    return (
      <div>
        <header className="blue-bg title">
          <h1 className="white subh1">Products</h1>
          <a href="contact" className="text-right white">Get a Quote <span className="yellow">→</span></a>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col-md-3 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Banner<span className="underline" /></h3>
                  <ul>
                    <li>Light curtains</li>
                    <li>Safety and emergency stop relays</li>
                    <li>Anti-tiedown systems</li>
                    <li>Safety switches</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Fortress Interlocks<span className="underline" /></h3>
                  <ul>
                    <li>Safety interlock trapped key systems</li>
                    <li>Modular pushbuttons and interlocks</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default MotorDrives;
