import React, { Component } from "react";
class ProductsSafety extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Products</h1>
          <a href="contact" className="text-right white"><span className="yellow">Get a Quote →</span></a>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Machine Safety Products</h2>
              <p className>We also carry products from the companies listed below and will be glad to assist
                you with any needs that you have.</p>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Banner<span className="underline" /></h3>
                  <ul>
                    <li>Light curtains</li>
                    <li>Safety and emergency stop relays</li>
                    <li>Anti-tiedown systems</li>
                    <li>Safety switches</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=banner" target="_blank">Shop Here</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Fortress Interlocks<span className="underline" /></h3>
                  <ul>
                    <li>Safety interlock trapped key systems</li>
                    <li>Modular pushbuttons and interlocks</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://fortress-safety.com/" target="_blank">Visit Site</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Larco<span className="underline" /></h3>
                  <ul>
                    <li>Safety mats and controls</li>
                  </ul>
                </div>
                <div className="card-footer">
                  <a className="read-more blue" href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=larco" target="_blank">Shop Here</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsSafety;
