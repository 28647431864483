import React, { Component } from "react";
class Contact extends Component {
  render() {
    return (
            <div>
            <section className="cta blue-gradient">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h2>Request a Quote</h2>
                    <p>Call us today at <strong className="yellow">1 800 922 4127</strong> or email us at the address below
                      </p>
                      <p>
                <a href="mailto:sales@bwclark.com">sales@BWClark.com</a>
                      </p>
                  </div>
                </div>
    {/*            <div className="row">
                  <div className="d-flex flex-column flex-md-row lead ml-auto mr-auto mt-5">
                    <Link to="contact" className="btn btn-lg btn-bd-primary mb-3 mb-md-0 yellow-bg blue" onclick="ga('send', 'event', 'Jumbotron actions', 'Get started', 'Get started');">Get in Touch</Link>
                  </div>
                </div> */}

              </div>
            </section>
            </div>
    );
  }
};
export default Contact;
