import React, { Component } from "react";
class ProductsWago extends Component {
  render() {
    return (
      <div>
        {/* <header class="productHeader">
          <h3 class="blue">Sensors, Logic Controls, Touch Panels & More</h3>
      </header> */}
        <header className="blue-bg title">
          <h1 className="white subh1">Manufacturers</h1>
          <a href="contact" className="text-right white"><span className="yellow">Get a Quote →</span></a>
        </header>
        <div className="product-view">
          <div className="row">
            <div className="col sub-header mb-3">
              <h2>Wago</h2>
              <p>Shop <a href="https://www.seltrolshop.com/products?utf8=%E2%9C%93&search=wago" target="_blank">Wago online</a> products here</p>
              <a href="http://www.wago.us/" target="_blank"><img className="product-logo" src="/images/brands/bw-wago.jpg" alt="Wago" /></a>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Rail Mounted Terminal Block Systems<span className="underline" /></h3>
                  <ul>
                    <li>TOPJOB® S</li>
                    <li>POWER CAGE CLAMP®</li>
                    <li>X-COM® System</li>
                    <li>Patchboard System</li>
                    <li>Side-Entry and Front-Entry</li>
                    <li>Compact and Miniature</li>
                    <li>Terminal Blocks with IDC Connection</li>
                    <li>Modular Terminal Blocks and terminal strips</li>
                    <li>Shield connecting system</li>
                    <li>Eex e II and Eex I Rail Mounted Terminal Blocks</li>
                    <li>Function Terminal Blocks</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>PCB Terminal Blocks and Connectors<span className="underline" /></h3>
                  <ul>
                    <li>Surface Mount Terminal Blocks for PCB’s</li>
                    <li>Pluggable PCB Terminal Blocks</li>
                    <li>Feedthrough Terminal Blocks</li>
                    <li>PCB Components for THR Soldering</li>
                    <li>MCS Connectors</li>
                    <li>X-COM®</li>
                    <li>WINSTA® Connectors</li>
                    <li>Specialty Connectors</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Terminal Blocks and Connectors<span className="underline" /></h3>
                  <ul>
                    <li>Power Supply Terminals</li>
                    <li>Lighting Connectors</li>
                    <li>Installation Terminal Blocks</li>
                    <li>Capacitor and Ballast Terminal Blocks</li>
                    <li>PCB Terminal Blocks for electronic ballasts</li>
                    <li>Plug-in connectors for rail lighting</li>
                    <li>WINSTA® Connectors for building installation</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Components for Automation<span className="underline" /></h3>
                  <ul>
                    <li>Wireless</li>
                    <li>WAGO-I/O System <span>(750,753,755 and 757)</span></li>
                    <li>Ethernet 2.0</li>
                    <li>WAGO-I/O-IPC (758 Series)</li>
                    <li>I/O-Software (Series 759)</li>
                    <li>WAGO-SPEEDWAY 767</li>
                    <li>Industrial ETHERNET</li>
                    <li>Interface Modules</li>
                    <li>Overvoltage Protection</li>
                    <li>Power Supplies</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 column">
              <div className="card border">
                <div className="card-body">
                  <h3>Accessories<span className="underline" /></h3>
                  <ul>
                    <li>Markers</li>
                    <li>Tools</li>
                    <li>Mounting Accessories</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
};
export default ProductsWago;
